import { Route, Routes } from 'react-router-dom'

import Main from 'pages/Main'
import SplashScreen from 'pages/Splash'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SplashScreen />} />
      <Route path="/main" element={<Main />} />
    </Routes>
  )
}

export default AppRoutes
