import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import { ChaClientModel } from './types'

export const fetchCustomers = createAsyncThunk<ChaClientModel[], undefined>(
  'customers/getAll',
  async (_, thunkAPI) => {
    const response = await api.get<ChaClientModel[]>('/customers')
    return response.data
  }
)
