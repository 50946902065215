import React from 'react'

import * as S from './Tag.styles'

export interface ITagProps {
  text: string
  isChecked?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Tag = ({ isChecked = false, text, onClick }: ITagProps) => {
  return (
    <S.Container isChecked={isChecked} onClick={(e) => onClick && onClick(e)}>
      {text}
    </S.Container>
  )
}
export default Tag
