import * as S from './ChatHeader.styles'
import { Dropdown } from '../../../../Components/Inputs'
import { HeaderContainerSC } from '../../shared/styleUtils'
import { useChat } from '../../../../models/chatClients/hooks'
import { useChannel } from '../../../../models/channel/hooks'
import { useAppSelector } from '../../../../store'
import { useUser } from '../../../../models/user/hooks'
import { useConfirmDialog } from '@seidor/orbit-ds-core'
import { useEffect } from 'react'

const ChatHeader = () => {
  const { users } = useAppSelector((state) => state.user)
  const { getChannels, filter } = useChannel({})
  const {
    handleAssignUser,
    handleSetChannelStatus,
    statusChannelStatus,
    selectedChannel,
    handleConfirCloseChannel,
  } = useChat()
  const { handleAllUsers } = useUser()
  const { ConfirmDialogComponent, closeConfirmDialog, openConfirmDialog } =
    useConfirmDialog()

  useEffect(() => {
    if (statusChannelStatus === 'CLOSED') openConfirmDialog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChannelStatus])

  return (
    <HeaderContainerSC>
      <S.Header>
        <Dropdown
          name="inputDropdownUsers"
          initialValue={selectedChannel?.userId}
          options={users}
          onOpen={() => {
            handleAllUsers()
          }}
          onChange={(value) => {
            if (value) {
              handleAssignUser(value)
              getChannels(filter)
            }
          }}
          placeholder="Atribuir atendente"
          disabled={selectedChannel ? false : true}
        />
        <Dropdown
          name="inputDropdown"
          initialValue={statusChannelStatus}
          placeholder="Status do canal"
          options={[
            { value: 'CREATED', text: 'Em processamento' },
            { value: 'PENDING', text: 'Pendente' },
            { value: 'OPEN', text: 'Aberto' },
            { value: 'FREEZE', text: 'Congelado' },
            { value: 'CLOSED', text: 'Fechado' },
          ]}
          onChange={handleSetChannelStatus}
          disabled={selectedChannel ? false : true}
        />
      </S.Header>
      <ConfirmDialogComponent
        title="Encerrar Atendimento"
        text="Você está encerrando este atendimento. Tem certeza que deseja continuar com essa ação?"
        color="alert"
        confirmButtonLabel="Confirmar"
        cancelButtonLabel="Cancelar"
        onConfirm={() => {
          handleConfirCloseChannel()
          closeConfirmDialog()
        }}
        onCancel={() => closeConfirmDialog()}
        size={'regular'}
      />
    </HeaderContainerSC>
  )
}

export default ChatHeader
