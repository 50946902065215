import * as S from './UserStatus.styles'
import { Text } from 'Components/Typography'
import { useUser } from 'models/user/hooks'
import { PopupMenu } from 'Components/DataDisplay'
import { Icon } from 'Components/DataDisplay'
import { IPopupMenuItemType } from 'Components/DataDisplay/PopupMenu'
import { getInitials } from 'commons/functions/strings'

const UserStatus = () => {
  const { data, userActive, handleSetStatus } = useUser()

  const menuItems: IPopupMenuItemType[] = [
    {
      label: 'Disponível',
      onSelectItem: () => handleSetStatus(0),
      value: '0',
    },
    {
      label: 'Ausente',
      onSelectItem: () => handleSetStatus(2),
      value: '2',
    },
    {
      label: 'Ocupado',
      onSelectItem: () => handleSetStatus(1),
      value: '1',
    },
  ]

  return (
    <S.Container>
      <S.AvatarContainer>
        <S.Avatar>{getInitials(data?.userName || '-')}</S.Avatar>
      </S.AvatarContainer>
      <div>
        <PopupMenu menuItems={menuItems} openWithClick>
          <>
            <S.Row>
              <Text weight="black" size="large" content={data?.name} />
              <Icon name="ph-arrow-down" size="20" />
            </S.Row>
            <S.UserActiveStatusContainer>
              <S.StatusBadge status={data?.userStatus} />
              <Text content={userActive[data?.userStatus]} weight="medium" />
            </S.UserActiveStatusContainer>
          </>
        </PopupMenu>
      </div>
    </S.Container>
  )
}

export default UserStatus
