import styled, { css } from 'styles/styled'
import { Text } from 'Components/Typography'

const customerBorderRadius = css`
  border-radius: ${({ theme }) =>
    `0 ${theme.border.radius.regular} ${theme.border.radius.regular} ${theme.border.radius.regular}`};
`

const userBorderRadius = css`
  border-radius: ${({ theme }) =>
    `${theme.border.radius.regular} 0 ${theme.border.radius.regular} ${theme.border.radius.regular}`};
`
export const Container = styled.li<{ isCustomer: boolean }>`
  display: flex;
  justify-content: ${({ isCustomer }) =>
    isCustomer ? 'flex-start' : 'flex-end'};
`

export const Message = styled.div<{ isCustomer: boolean }>`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[5]}`};
  ${({ isCustomer }) => (isCustomer ? customerBorderRadius : userBorderRadius)}
  background-color: ${({ isCustomer, theme }) =>
    isCustomer ? theme.color.greyScale[50] : theme.color.primary[400]};
`
export const MessageText = styled(Text)<{ isCustomer: boolean }>`
  color: ${({ isCustomer, theme }) =>
    isCustomer ? theme.color.black : theme.color.white};
`
