import { useCallback, useMemo } from 'react'
import { formatDistanceIntl } from 'commons/functions/dates'
import { IChannelModel } from 'models/channel/types'
import * as S from './ChannelCard.styles'
import { IMessageModel } from 'models/messages/types'
import { format } from 'commons/functions/contact'

export interface IChannelCard {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  data?: IChannelModel | null
}

const ChannelCard = ({ onClick, data }: IChannelCard) => {
  const lastMessageDate = useMemo(() => {
    let arr = new Array<IMessageModel>()
    if (data && data.messages) arr = [...arr, ...data.messages]
    if (arr.length) {
      const lastDate = arr[arr.length - 1].created_at

      return formatDistanceIntl(new Date(lastDate), new Date())
    }

    return null
  }, [data])

  const renderLastMessage = useCallback(() => {
    if (data && data.messages && data.messages.length) {
      const msg = data.messages[data.messages.length - 1]
      if (msg.textMessage.length > 30) {
        return msg.textMessage.substring(0, 30) + '...'
      }

      return msg.textMessage
    }

    return ''
  }, [data])

  return (
    <S.Container
      onClick={(e) => onClick && onClick(e)}
      data-testid="channel-card"
    >
      <S.Header>
        <S.CustomerNameText
          content={
            data?.customer?.customerName ?? format(data?.customer?.contact)
          }
        />
        <S.DateText content={lastMessageDate} />
      </S.Header>
      <S.MessageText content={renderLastMessage()} />
    </S.Container>
  )
}
export default ChannelCard
