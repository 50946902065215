import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import { ICustomerRequest, ICustomerResponse } from './types'

export const updateCustomer = createAsyncThunk<
  ICustomerResponse,
  ICustomerRequest
>('customer/update', async (data, thunkAPI) => {
  const _data = {
    customerName: data.customerName,
    customerMail: data.customerMail,
    contact: data.contact,
  }
  const response = await api.put<ICustomerResponse>(
    `cx-messages-service/api/customers/${data.id}`,
    _data
  )

  return response.data
})
