import { useMessage } from 'models/messages/hooks'
import { useAppSelector } from 'store'
import * as S from './Chat.styles'
import ChatInput from './parts/ChatInput'
import MessageList from './parts/MessageList'

const Chat = () => {
  const { data } = useAppSelector((state) => state.messages)

  const { sendMessage } = useMessage()

  return (
    <S.ChatContainer>
      {!!data?.length && <MessageList />}
      <S.InputContainer>
        <ChatInput onSubmit={(value) => sendMessage(value.chat_message)} />
      </S.InputContainer>
    </S.ChatContainer>
  )
}

export default Chat
