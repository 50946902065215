import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFetchUserResponse, IUserModel, IUserOptions } from './types'
import {
  createBotMakerAgent,
  fetchAllUsers,
  fetchUser,
  setUserStatus,
} from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  data: IUserModel
  users: IUserOptions[]
  status: loadingStatusType
  statusAllUsers: loadingStatusType
  error: string
}

const initialState: IInitialState = {
  data: null,
  users: [],
  status: 'idle',
  statusAllUsers: 'idle',
  error: '',
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addClient: (state, { payload }: PayloadAction<IUserModel>) => {
      state.data = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchUser.fulfilled,
        (state, { payload }: PayloadAction<IFetchUserResponse>) => {
          state.data = payload.data
          state.status = 'idle'
        }
      )
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.error.message
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(setUserStatus.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.status = 'success'
      })
      .addCase(setUserStatus.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(setUserStatus.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        const users = action.payload?.data?.map((item) => {
          return {
            value: item?.id,
            text: item?.name,
          }
        })

        state.users = users
        state.statusAllUsers = 'success'
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.statusAllUsers = 'error'
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.statusAllUsers = 'loading'
      })
      .addCase(createBotMakerAgent.fulfilled, (state) => {
        state.statusAllUsers = 'success'
      })
      .addCase(createBotMakerAgent.rejected, (state) => {
        state.statusAllUsers = 'error'
      })
      .addCase(createBotMakerAgent.pending, (state) => {
        state.statusAllUsers = 'loading'
      })
  },
})

// Action creators are generated for each case reducer function
export const { addClient } = user.actions

export default user.reducer
