import baseStyled, {
  css as baseCss,
  ThemedStyledInterface,
  ThemedCssFunction,
  ThemedStyledComponentsModule,
  createGlobalStyle as baseCreateGlobalStyle,
} from 'styled-components'
import { ContextType } from '@seidor/orbit-ds-core'

type ITheme = ContextType['theme']

const styled = baseStyled as ThemedStyledInterface<ITheme>

export default styled
export const css = baseCss as ThemedCssFunction<ITheme>
export const createGlobalStyle =
  baseCreateGlobalStyle as ThemedStyledComponentsModule<ITheme>['createGlobalStyle']
