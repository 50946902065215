import axios from 'axios'
import type { Store } from '../store'

let store: Store = null

export function injectStore(_store: Store) {
  store = _store
}

const handleError = (data: any) => {
  return Promise.reject(data)
}

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_ORBIT,
  timeout: 30000,
})

api.interceptors.request.use(
  async (request) => {
    if (!request.url.includes('/auth')) {
      const state = store.getState()
      const { data: dataApp } = state.app

      request.headers = {
        ...request.headers,
        'x-api-key': dataApp?.data.pk,
        token: dataApp?.token,
      }
    }

    return request
  },
  (error) => handleError(error)
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    return handleError(error)
  }
)

export { api }
