import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { updateCustomer } from './services'
import { ICustomerModel } from './types'
import { fetchChannels } from '../channel/services'
import { selectChannel } from 'models/channel/model'

export const useCustomer = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const { selectedChannel } = useAppSelector((state) => state.channels)

  useEffect(() => {
    setCustomer(selectedChannel?.customer)
  }, [selectedChannel])

  const [edit, setEdit] = useState<boolean>(false)
  const [customer, setCustomer] = useState<ICustomerModel>({
    id: '',
    channels: [],
    contact: '',
    messages: [],
    tenantid: '',
    customerMail: '',
    customerName: '',
  })

  const toggleEdit = useCallback(() => {
    setEdit(!edit)
  }, [edit])

  const handleCustomer = useCallback((data: ICustomerModel) => {
    setCustomer(data)
  }, [])

  const handleCustomerEdit = useCallback(() => {
    let params = {}

    dispatch(updateCustomer(customer))
      .then(() => dispatch(fetchChannels({ userId: user.data.id, params })))
      .then(() =>
        dispatch(selectChannel({ ...selectedChannel, customer: customer }))
      )
      .catch((err) => console.log(err))

    toggleEdit()
  }, [customer, dispatch, toggleEdit, user, selectedChannel])

  const handleChangeCustomer = useCallback(
    (property: string) => (data: string) => {
      setCustomer({
        ...customer,
        [property]: data,
      })
    },
    [customer]
  )

  return {
    ...user,
    edit,
    toggleEdit,
    handleCustomerEdit,
    customer,
    handleCustomer,
    handleChangeCustomer,
  }
}
