import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { clearTickets } from './model'
import { postTicket, fetchTickets } from './services'
import { ITicketModel, IUseTicketProps } from './types'
import {
  causeCategories,
  exchangesCategory,
  generalInformationCategory,
  pharmaceuticalAttentionCategory,
  pharmacovigilanceCategory,
  protestCategory,
  salesPointCategory,
  solicitationsCategory,
} from 'pages/Main/parts/CustomerDetails/parts/helpers'

export function useTicket({ customerId, selectedChannel }: IUseTicketProps) {
  const dispatch = useAppDispatch()
  const { data: ticketsData, status } = useAppSelector((state) => state.tickets)
  const channels = useAppSelector((state) => state.channels)
  console.log(customerId, selectedChannel)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [ticket, setTicket] = useState<ITicketModel>({
    Name: '',
    BuyerPartyID: '',
    ServicePriorityCode: '',
    ReportedPartyID: '',
    ServiceIssueCategoryID: '',
    CauseServiceIssueCategoryID: '',
    ObjectServiceIssueCategoryID: '',
  })

  const handleTickets = useCallback(
    (customerId: string) => {
      if (customerId)
        dispatch(
          fetchTickets({
            customerId: channels?.selectedChannel?.customer?.externalId,
          })
        )
    },
    [dispatch, channels?.selectedChannel?.customer?.externalId]
  )

  useEffect(() => {
    if (!channels.selectedChannel) {
      dispatch(clearTickets())
    } else {
      handleTickets(channels?.selectedChannel?.customer?.externalId)
    }
  }, [channels.selectedChannel, dispatch, handleTickets])

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  const handleSubmitTicket = useCallback(() => {
    let data = ticket
    data.ServiceIssueCategoryID = 'CA_3'
    data.BuyerPartyID = channels?.selectedChannel?.customer?.externalId
    data.ReportedPartyID = channels?.selectedChannel?.customer?.externalId
    data.ChannelId = selectedChannel

    dispatch(postTicket(data))
    toggleModal()
  }, [
    ticket,
    dispatch,
    toggleModal,
    channels?.selectedChannel?.customer?.externalId,
  ])

  const handleChangeTicket = useCallback(
    (property: string) => (value: string) => {
      setTicket({
        ...ticket,
        [property]: value,
      })
    },
    [ticket]
  )

  const renderCategoy = useMemo(() => {
    switch (ticket.IncidentServiceIssueCategoryID) {
      case 'CA_23':
        return causeCategories.filter((item) =>
          salesPointCategory.includes(item.value)
        )
      case 'CA_19':
        return causeCategories.filter((item) =>
          protestCategory.includes(item.value)
        )
      case 'CA_4':
        return causeCategories.filter((item) =>
          pharmaceuticalAttentionCategory.includes(item.value)
        )
      case 'CA_44':
        return causeCategories.filter((item) =>
          exchangesCategory.includes(item.value)
        )
      case 'CA_38':
        return causeCategories.filter((item) =>
          generalInformationCategory.includes(item.value)
        )
      case 'CA_30':
        return causeCategories.filter((item) =>
          solicitationsCategory.includes(item.value)
        )
      case 'CA_27':
        return causeCategories.filter((item) =>
          pharmacovigilanceCategory.includes(item.value)
        )
      default:
        return causeCategories
    }
  }, [ticket.IncidentServiceIssueCategoryID])

  return {
    ticketsData,
    showModal,
    ticket,
    renderCategoy,
    toggleModal,
    handleSubmitTicket,
    handleChangeTicket,
    handleTickets,
    status,
  }
}
