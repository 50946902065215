import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import { IUnreadMessageModel } from './types'

export const fetchCustomers = createAsyncThunk<
  IUnreadMessageModel[],
  undefined
>('customers/getAll', async (_, thunkAPI) => {
  const response = await api.get<IUnreadMessageModel[]>('/')
  return response.data
})
