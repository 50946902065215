import { Provider } from 'react-redux'
import { store } from 'store'
import { ReactNode } from 'react'
import AppThemeProvider from './AppThemeProvider'
import { injectStore } from 'services/client'
import { BrowserRouter } from 'react-router-dom'

injectStore(store)

interface IAppProvider {
  children: ReactNode
}

function AppProvider({ children }: IAppProvider) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppThemeProvider>{children}</AppThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default AppProvider
