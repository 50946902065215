import {
  FullPageLoader as DSFullPageLoader,
  IFullPageLoaderProps as IDSFullPageLoaderProps,
} from '@seidor/orbit-ds-core'

export interface IFullPageLoaderProps extends IDSFullPageLoaderProps {}

export default function FullPageLoader(props: IDSFullPageLoaderProps) {
  return <DSFullPageLoader {...props} />
}
