export const getInitials = (value: string) => {
  if (value && value.length >= 1) {
    const arr = value.split(' ')
    if (arr.length >= 2) {
      return `${arr[0].charAt(0).toUpperCase()}${arr[1]
        .charAt(0)
        .toUpperCase()}`
    } else if (arr.length === 1) {
      return `${arr[0].charAt(0).toUpperCase()}`
    } else {
      return ''
    }
  }

  return ''
}
