import { useCallback } from 'react'
import MessageCard from './parts/MessageCard'
import * as S from './MessageList.styles'
import { useMessage } from 'models/messages/hooks'
import { IMessageModel } from 'models/messages/types'

export default function MessageList() {
  const { data, divRef } = useMessage()

  const renderItems = useCallback((data) => {
    return data?.map((item: IMessageModel) => (
      <MessageCard
        key={item?.id}
        isCustomer={item?.sender === 2}
        messageText={item?.textMessage}
      />
    ))
  }, [])

  return (
    <S.MessageListContainer>
      {renderItems(data)}
      <div ref={divRef} />
    </S.MessageListContainer>
  )
}
