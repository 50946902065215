import styled from 'styles/styled'
import { ListSpacing, SectionPadding } from 'pages/Main/shared/styleUtils'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${SectionPadding}
`

export const CustomerAvatarContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing[3]} 0px`};
`

export const CustomerLoadingContainer = styled.div`
  margin-top: 1em;
`

export const HeaderActivity = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing[3]} 0`};
`
export const TicketListContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing[4]} 0`};
  margin-top: ${({ theme }) => theme.spacing[4]};
  ${ListSpacing}
`
export const CustomerInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.spacing[3]} 0px`};
  gap: ${({ theme }) => `${theme.spacing[3]}`};
`
export const CustomerInformationColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomerInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin: ${({ theme }) => `${theme.spacing[3]} 0px`};
  gap: ${({ theme }) => `${theme.spacing[3]}`};
`

export const ActivityHeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => `${theme.spacing[3]} 0px`};
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ActivityListContainer = styled.div`
  overflow-y: auto;
  ${ListSpacing}
`

export const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacing[5]}`};
`

export const FormButtonsContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => `${theme.spacing[5]}`};

  button {
    width: 80px;
    margin: 0 !important;
  }
`
