import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import {
  IAssignChannelResponse,
  IAssignUserRequest,
  IChannelResponse,
  IChannelStatusRequest,
  IFetchChannelsPayload,
} from './types'

export const fetchChannels = createAsyncThunk<
  IChannelResponse,
  IFetchChannelsPayload
>('channels/getAll', async ({ userId, params }, thunkAPI) => {
  const response = await api.get<IChannelResponse>(
    `cx-messages-service/api/channels/${userId}`,
    {
      params,
    }
  )

  return response.data
})

export const setAssignUserChannel = createAsyncThunk<
  IAssignChannelResponse,
  IAssignUserRequest
>('channels/assignUser', async ({ id, userId, firstInteraction }, thunkAPI) => {
  const response = await api.put<IAssignChannelResponse>(
    `cx-messages-service/api/channels/${id}`,
    {
      userId,
    },
    {
      params: {
        firstInteraction,
      },
    }
  )

  return response.data
})

export const updateChannelStatus = createAsyncThunk<
  IAssignChannelResponse,
  IChannelStatusRequest
>('channels/updateStatus', async ({ id, channelStatus }, thunkAPI) => {
  const response = await api.put<IAssignChannelResponse>(
    `cx-messages-service/api/channels/status/${id}`,
    {
      channelStatus,
    }
  )

  return response.data
})

export const closeChannelStatus = createAsyncThunk<
  IAssignChannelResponse,
  IChannelStatusRequest
>('channels/closeChannel', async ({ id }, thunkAPI) => {
  const response = await api.put<IAssignChannelResponse>(
    `cx-messages-service/api/channels/close/${id}`
  )

  return response.data
})

export const searchChannel = createAsyncThunk<
  IChannelResponse,
  IChannelStatusRequest
>('channels/searchChannel', async ({ id, channelName }, thunkAPI) => {
  const response = await api.get<IChannelResponse>(
    `cx-messages-service/api/channels/${id}`,
    { params: { channelName } }
  )

  return response.data
})
