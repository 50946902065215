import { SectionPadding } from 'pages/Main/shared/styleUtils'
import styled from 'styles/styled'

export const ChatContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${SectionPadding}
`
export const InputContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing[3]};
  padding-bottom: ${({ theme }) => theme.spacing[3]};
`
