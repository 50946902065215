import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAppModel } from './types'
import { appAuth } from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  data: IAppModel
  status: loadingStatusType
  error: string | null
}

const initialState: IInitialState = {
  data: null,
  status: 'loading',
  error: null,
}

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        appAuth.fulfilled,
        (state: IInitialState, action: PayloadAction<IAppModel>) => {
          state.data = action.payload
          state.status = 'idle'
        }
      )
      .addCase(appAuth.rejected, (state: IInitialState, action) => {
        state.status = 'error'
        state.error = action.error.message
      })
      .addCase(appAuth.pending, (state: IInitialState) => {
        state.status = 'loading'
      })
  },
})

export default app.reducer
