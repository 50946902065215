import AppRoutes from 'routes'
import AppProvider from 'commons/providers/AppProvider'

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  )
}

export default App
