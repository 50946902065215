import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ChaClientModel } from './types'
import { fetchCustomers } from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  clients: ChaClientModel[]
  fetchClientsLoadingStatus: loadingStatusType
}

const initialState: IInitialState = {
  clients: [],
  fetchClientsLoadingStatus: 'idle',
}

export const chatClients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    addClient: (state, { payload }: PayloadAction<ChaClientModel>) => {
      state.clients.push(payload)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        return {
          ...state,
          clients: action.payload,
          fetchClientsLoadingStatus: 'success',
        }
      })
      .addCase(fetchCustomers.rejected, (state) => {
        return { ...state, clients: [], fetchClientsLoadingStatus: 'error' }
      })
      .addCase(fetchCustomers.pending, (state) => {
        return { ...state, fetchClientsLoadingStatus: 'loading' }
      })
  },
})

// Action creators are generated for each case reducer function
export const { addClient } = chatClients.actions

export default chatClients.reducer
