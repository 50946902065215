import { useCallback, useState } from 'react'
import * as S from './TicketCard.styles'
import Information from '../shared/Information'
import { Icon } from 'Components/DataDisplay'
import { Text } from 'Components/Typography'
import { ITicketModel } from 'models/tickets/types'
import { occurrenceCategories, causeCategories, priorities } from '../helpers'
import { formatDate } from 'commons/functions/dates'

interface IProps {
  data?: ITicketModel
}

const TicketCard = ({ data }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  function handleClick() {
    setIsOpen(!isOpen)
  }

  const renderCategory = useCallback(() => {
    return occurrenceCategories.find(
      (item) => item.value === data?.IncidentServiceIssueCategoryID
    )?.text
  }, [data?.IncidentServiceIssueCategoryID])

  const renderCause = useCallback(() => {
    return causeCategories?.find(
      (item) => item.value === data?.CauseServiceIssueCategoryID
    )?.text
  }, [data?.CauseServiceIssueCategoryID])

  const renderPriority = useCallback(() => {
    return priorities?.find((item) => item.value === data?.ServicePriorityCode)
      ?.text
  }, [data?.ServicePriorityCode])

  return (
    <S.Container>
      <S.Header>
        <div>
          <div>
            <Text content={`#${data?.ID}`} />
          </div>
          <Text content={data?.Name} weight="black" size="large" />
        </div>
        <S.ChevronIconContainer onClick={handleClick}>
          <Icon
            color="black"
            name={isOpen ? 'ph-arrow-up' : 'ph-arrow-down'}
            size="30"
            stroke="thin"
          />
        </S.ChevronIconContainer>
      </S.Header>
      <S.Body isOpen={isOpen}>
        <Information label="Categoria do serviço" text={renderCategory()} />
        <Information label="Categoria da Ocorrência" text={renderCause()} />
        <Information
          label="Conclusão até"
          text={formatDate(
            data?.RequestedFulfillmentPeriodEndDateTime,
            'dd/MM/yyyy'
          )}
        />
        <Information
          label="Status"
          text={data?.ServiceRequestLifeCycleStatusCodeText}
        />
        <Information label="Prioridade" text={renderPriority()} />
      </S.Body>
    </S.Container>
  )
}
export default TicketCard
