import { intlFormatDistance, format, parse } from 'date-fns'

export function formatDistanceIntl(
  date: Date | number,
  baseDate: Date | number,
  options?: {
    locale?: string
  }
) {
  return intlFormatDistance(date, baseDate, {
    locale: options?.locale || 'pt-BR',
  })
}

export function formatDate(
  date: Date | string,
  pattern: string = 'dd/MM/yyyy'
): string {
  if (!date) return ''

  let dataObj: Date

  if (typeof date === 'string') {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(date)) {
      dataObj = parse(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date())
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      dataObj = parse(date, 'yyyy-MM-dd', new Date())
    } else {
      const [day, month, year] = date.split('/')
      dataObj = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
    }
  } else {
    dataObj = date
  }

  return format(dataObj, pattern)
}
