import Form from 'Components/Form'
import { Input } from 'Components/Inputs'
import { useEffect, useState } from 'react'
import * as S from './ChatInput.styles'

type ChatSubmitValuesType = {
  chat_message: string
}

export interface IChatInputProps {
  onSubmit: (value: ChatSubmitValuesType) => void | Promise<void>
  initialValue?: string
}

export default function ChatInput(props: IChatInputProps) {
  const [message, setMessage] = useState(props.initialValue || '')

  useEffect(() => {
    setMessage(props.initialValue || '')
  }, [props.initialValue])

  function handleSubmit(values: ChatSubmitValuesType) {
    if (!values.chat_message) return
    props.onSubmit(values)
    setMessage('')
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        data-testid="input_new_message"
        iconLeft={{
          name: 'ph-chat-teardrop-text',
        }}
        iconRight={
          {
            name: 'ph-paper-plane-right',
            onClick: () => handleSubmit({ chat_message: message }),
          } as any
        }
        initialValue={message}
        onChange={(val) => setMessage(val)}
        name="chat_message"
        type="text"
      />
      <S.InvisibleButton type="submit" />
    </Form>
  )
}
