import { useCallback, useEffect, createRef } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { addMessages, setMessages } from './model'
import { IMessageModel, IUserSendMessage } from './types'
import { addNewChannel, updateChannelsData } from 'models/channel/model'
import { sendMessage as sendMessageToCustomer } from './services'
import { IChannelModel } from 'models/channel/types'

export const useMessage = () => {
  const dispatch = useAppDispatch()
  const messages = useAppSelector((state) => state.messages)
  const { selectedChannel } = useAppSelector((state) => state.channels)
  const { data: userData } = useAppSelector((state) => state.user)

  const divRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!selectedChannel && messages.data.length > 0) {
      dispatch(setMessages([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel, messages])

  const updateChannel = useCallback(
    (message: IMessageModel) => {
      dispatch(updateChannelsData(message))
      dispatch(addMessages(message))
    },
    [dispatch]
  )

  const updateMessageFromUser = useCallback(
    (message: IMessageModel) => {
      dispatch(updateChannelsData(message))
      dispatch(addMessages(message))
    },
    [dispatch]
  )

  const handleNewChannel = useCallback(
    (data: IChannelModel) => {
      dispatch(addNewChannel(data))
    },
    [dispatch]
  )

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages.data, divRef])

  const handleSetMessages = useCallback(
    (messages: IMessageModel[]) => {
      dispatch(setMessages(messages))
    },
    [dispatch]
  )

  const sendMessage = useCallback(
    (message: string) => {
      const msg: IUserSendMessage = {
        userId: userData.id,
        channelId: selectedChannel.id,
        textMessage: message,
      }

      dispatch(sendMessageToCustomer(msg))
    },
    [selectedChannel, userData, dispatch]
  )

  return {
    ...messages,
    handleSetMessages,
    sendMessage,
    divRef,
    updateChannel,
    handleNewChannel,
    updateMessageFromUser,
  }
}
