import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { fetchChannels } from './services'
import { selectChannel } from './model'
import {
  IChannelModel,
  IChannelParams,
  IChannelUsecaseProps,
  FilterValueType,
} from './types'

export const useChannel = (props: IChannelUsecaseProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const channels = useAppSelector((state) => state.channels)

  const { initialValue = 'ALL' } = props

  const [filter, setFilter] = React.useState<FilterValueType>(initialValue)

  const handleSetSelected = useCallback(
    (channel: IChannelModel) => {
      if (channel?.id === channels?.selectedChannel?.id)
        return dispatch(selectChannel(null))

      return dispatch(selectChannel(channel))
    },
    [dispatch, channels?.selectedChannel?.id]
  )

  const getChannels = useCallback(
    (filter: FilterValueType) => {
      let params: IChannelParams = {}

      if (filter === 'NOT_READ') params.unreadMessages = true
      if (filter === 'READ') params.messages = true
      if (filter === 'ALL') params = { messages: true, unreadMessages: true }

      dispatch(fetchChannels({ userId: user?.data?.id, params }))
    },
    [dispatch, user?.data?.id]
  )

  const onChangeFilterTags = React.useCallback((tag: FilterValueType) => {
    setFilter(tag)
  }, [])

  return {
    ...channels,
    getChannels,
    filter,
    onChangeFilterTags,
    handleSetSelected,
  }
}
