import { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { batch } from 'react-redux'
import { FullPageLoader } from '../../Components/Loader'
import { useUser } from '../../models/user/hooks'
import { useApp } from '../../models/app/hooks'

import * as S from './Splash.styles'
import { persistor, useAppDispatch } from 'store'
import { fetchAllUsers } from 'models/user/services'
import { fetchChannels } from 'models/channel/services'

function SplashScreen() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  let fired = useRef<number>(0)

  const [searchParams] = useSearchParams()
  const {
    handleParams,
    error: userError,
    status: userStatus,
    data: userData,
  } = useUser()
  const {
    bootstrapApp,
    handleReset,
    error: appError,
    status: appStatus,
  } = useApp()

  useEffect(() => {
    const userName = searchParams.get('userName')
    const userID = searchParams.get('userID')
    if (fired.current === 0) {
      fired.current = 1
      batch(() => {
        handleReset()
        bootstrapApp().then(() => {
          handleParams({ userName, externalID: userID })
        })
      })
    }
  }, [bootstrapApp, handleParams, handleReset, searchParams, dispatch])

  useEffect(() => {
    const userName = searchParams.get('userName')
    console.log(userData, userName)
    if (userStatus === 'success' && userName !== userData?.userName) {
      console.log(userStatus, 'chegou aqui')
      persistor.purge()
      return navigate('/')
    }

    if (userName === userData?.userName && userData?.registered && !userError) {
      dispatch(fetchAllUsers())
      dispatch(
        fetchChannels({
          userId: userData?.id,
          params: {
            messages: true,
            unreadMessages: true,
          },
        })
      )
      navigate('/main')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userError, userData?.registered, userData?.id, searchParams])

  if (appStatus === 'error' && appError)
    return (
      <S.Wrapper>
        <S.Text>Ocorreu um erro ao carregar sistema.</S.Text>
      </S.Wrapper>
    )

  if (userStatus === 'error' && userError)
    return (
      <S.Wrapper>
        <S.Text>Ocorreu um erro ao efetuar login no sistema.</S.Text>
      </S.Wrapper>
    )

  return <FullPageLoader loading={true} />
}

export default SplashScreen
