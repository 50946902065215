import {
  PopupMenu as DsPopupMenu,
  IPopupMenuProps as IDsPopupMenuProps,
} from '@seidor/orbit-ds-core'
import type { PopupMenuItemType as TDsPopupMenuItemType } from '@seidor/orbit-ds-core/dist/components/DataDisplay/PopupMenu/types'

export interface IPopupMenuItemType extends TDsPopupMenuItemType {}

export interface IPopupMenuProps extends IDsPopupMenuProps {}

export default function PopupMenu(props: IPopupMenuProps) {
  return <DsPopupMenu {...props} />
}
