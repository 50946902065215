import { Text } from 'Components/Typography'
import { HeaderContainerSC } from '../../shared/styleUtils'

export default function ChannelListHeader() {
  return (
    <HeaderContainerSC>
      <Text content="Mensagens" size="large" weight="black" />
    </HeaderContainerSC>
  )
}
