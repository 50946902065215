import { Input } from 'Components/Inputs'
import { Text } from 'Components/Typography'
import * as S from './Information.styles'

export interface IInformationProps {
  label: string
  text: string
  edit?: boolean
  onChange?: (value: string) => void
  type?:
    | 'cpf'
    | 'cnpj'
    | 'email'
    | 'tel'
    | 'cep'
    | 'text'
    | 'numerictext'
    | 'text'
    | 'numerictext'
}
const Information = ({
  label,
  text,
  edit,
  onChange,
  type,
}: IInformationProps) => {
  return (
    <S.ItemContainer>
      <div>
        <Text content={label} />
      </div>
      {edit ? (
        <Input
          name={label}
          type={type}
          label={text}
          onChange={onChange && onChange}
        />
      ) : (
        <Text content={text} weight="bold" size="large" />
      )}
    </S.ItemContainer>
  )
}
export default Information
