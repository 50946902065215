import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import { IUserProps } from './types'
import { fetchAllUsers, fetchUser, setUserStatus } from './services'

export const useUser = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { data, status, statusAllUsers, users, error } = useAppSelector(
    (state) => state.user
  )

  useEffect(() => {
    if (data?.registered && (status === 'success' || status === 'idle'))
      navigate('/main')
  }, [data, status, navigate])

  const userActive = {
    0: 'Disponível',
    1: 'Ocupado',
    2: 'Ausente',
  }

  const handleAllUsers = useCallback(() => {
    dispatch(fetchAllUsers())
  }, [dispatch])

  const handleSetStatus = useCallback(
    (userStatus: number) => {
      dispatch(setUserStatus({ id: data?.id, userStatus }))
    },
    [data?.id, dispatch]
  )

  const handleParams = useCallback(
    (values: IUserProps) => {
      return dispatch(fetchUser(values))
    },
    [dispatch]
  )

  return {
    data,
    status,
    handleParams,
    userActive,
    handleSetStatus,
    handleAllUsers,
    statusAllUsers,
    users,
    error,
  }
}
