import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ITicketModel, ITicketResponse } from './types'
import { fetchTickets, postTicket } from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  data: ITicketModel[]
  status: loadingStatusType
}

const initialState: IInitialState = {
  data: null,
  status: 'idle',
}

export const ticket = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetTickets: (state) => {
      state.data = null
    },
    setTickets: (state, { payload }: PayloadAction<ITicketModel>) => {
      const tickets = [payload, ...state.data]
      state.data = tickets
    },
    clearTickets: (state) => {
      state.data = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        fetchTickets.fulfilled,
        (state, { payload }: PayloadAction<ITicketResponse>) => {
          state.data = payload.data.sort((a: ITicketModel, b: ITicketModel) => {
            var timestampA = parseInt(a.CreationDateTime.match(/\d+/)[0])
            var timestampB = parseInt(b.CreationDateTime.match(/\d+/)[0])
            return timestampB - timestampA
          })
          state.status = 'idle'
        }
      )
      .addCase(fetchTickets.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchTickets.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(postTicket.fulfilled, (state, action) => {
        if (state.data?.length) state.data.push(action.payload.data)
        else state.data = [action.payload.data]
        state.status = 'success'
      })
      .addCase(postTicket.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(postTicket.pending, (state) => {
        state.status = 'loading'
      })
  },
})

// Action creators are generated for each case reducer function
export const { setTickets, clearTickets, resetTickets } = ticket.actions

export default ticket.reducer
