import { css } from '../styled'

const scrollBar = css`
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
`

export default scrollBar
