import { Tag } from 'Components/Buttons'
import { useCallback, useEffect } from 'react'
import { IFilterTagsProps, FilterValueType } from './types'
import * as S from './FilterTags.styles'
import { useChannel } from 'models/channel/hooks'

export default function FilterTags(props: IFilterTagsProps) {
  const { onChangeFilterTags, filter, getChannels } = useChannel(props)

  const onChangeFilter = useCallback(
    (filter: FilterValueType) => {
      if (!filter) return

      onChangeFilterTags(filter)
      getChannels(filter)
    },
    [getChannels, onChangeFilterTags]
  )

  useEffect(() => {
    onChangeFilterTags(props.initialValue)
  }, [props.initialValue, onChangeFilterTags])

  return (
    <S.FilterTagContainer>
      <Tag
        text="Todos"
        isChecked={filter === 'ALL'}
        onClick={() => {
          onChangeFilter('ALL')
        }}
      />
      <Tag
        text="Lidas"
        isChecked={filter === 'READ'}
        onClick={() => onChangeFilter('READ')}
      />
      <Tag
        text="Não Lidas"
        isChecked={filter === 'NOT_READ'}
        onClick={() => onChangeFilter('NOT_READ')}
      />
    </S.FilterTagContainer>
  )
}
