import * as S from './MessageCard.tyles'

export interface IMessageCardProps {
  messageText: string
  isCustomer: boolean
}
const MessageCard = ({ messageText, isCustomer }: IMessageCardProps) => {
  return (
    <S.Container isCustomer={isCustomer}>
      <S.Message isCustomer={isCustomer}>
        <S.MessageText isCustomer={isCustomer} content={messageText} />
      </S.Message>
    </S.Container>
  )
}
export default MessageCard
