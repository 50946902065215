import styled from 'styles/styled'
import { AvatarCircle, SectionPadding } from 'pages/Main/shared/styleUtils'
import { ITheme } from '@seidor/orbit-ds-core/dist/components/ThemeProvider/ITheme'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${SectionPadding}
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
`

export const AvatarContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing[3]};
`
export const Avatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  ${AvatarCircle}
`
export const UserActiveStatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
`

export const StatusBadge = styled.div<{ theme: ITheme; status: number }>`
  height: 10px;
  aspect-ratio: 1;
  background-color: ${({ theme, status }) =>
    status === 0
      ? theme.color.success.main
      : status === 1
      ? theme.color.error.main
      : status === 2
      ? theme.color.attention.main
      : theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius.circle};
`

export const Row = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5px;
`
