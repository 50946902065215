import styled from 'styles/styled'
import { AvatarCircle } from 'pages/Main/shared/styleUtils'
import { Text } from 'Components/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
`

export const AvatarContainer = styled.div`
  margin: ${({ theme }) => theme.spacing[4]};
`
export const Avatar = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  ${AvatarCircle}
`
export const CustomerNameText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.headers.h4};
`
