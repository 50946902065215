import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import { IMessageModel, IUserSendMessage } from './types'

export const fetchCustomers = createAsyncThunk<IMessageModel[], undefined>(
  'customers/getAll',
  async (_, thunkAPI) => {
    const response = await api.get<IMessageModel[]>(
      'cx-messages-service/api/tenantid/getAll'
    )
    return response.data
  }
)

export const sendMessage = createAsyncThunk<string, IUserSendMessage>(
  'message/sendMessage',
  async (data, thunkApi) => {
    const response = await api.post<string>(
      'cx-messages-service/api/channels/userSendMessage',
      data
    )
    return response.data
  }
)
