import { useCallback, useEffect, useState } from 'react'
import { Tabs } from 'Components/Navigation'
import Information from './parts/shared/Information'
import { Button } from 'Components/Buttons'
import CustomerAvatar from './parts/CustomerAvatar'
import TicketCard from './parts/TicketCard'
import * as S from './CustomerDetails.styles'
import { Text } from 'Components/Typography'
import { useAppSelector } from 'store'
import { format } from 'commons/functions/contact'
import { formatDistanceIntl } from 'commons/functions/dates'
import Form from 'Components/Form'
import { useCustomer } from 'models/customer/hooks'
import { Modal } from 'Components/Modal'
import { useTicket } from 'models/tickets/hooks'
import { Dropdown, Input, Textarea } from 'Components/Inputs'
import { ITicketModel } from 'models/tickets/types'
import { occurrenceCategories, priorities } from './parts/helpers'
import { Loading } from 'Components/Loading'

const CustomerDetails = () => {
  const { selectedChannel } = useAppSelector((state) => state.channels)

  console.log(selectedChannel, 'selectedChannel')

  const {
    toggleEdit,
    edit,
    customer,
    handleCustomerEdit,
    handleChangeCustomer,
    handleCustomer,
  } = useCustomer()
  const {
    showModal,
    handleSubmitTicket,
    handleChangeTicket,
    toggleModal,
    ticketsData,
    renderCategoy,
    status,
  } = useTicket({
    customerId: selectedChannel?.customer?.externalId,
    selectedChannel: selectedChannel?.id,
  })

  const [tabActive, setTabActive] = useState<'PROFILE' | 'TICKETS'>('PROFILE')
  const [ticketsDataWrittable, setTicketsDataWrittable] =
    useState<typeof ticketsData>()

  const renderActivities = useCallback(() => {
    return selectedChannel?.channelUserActions?.map((item) => {
      const date = item?.created_at
        ? formatDistanceIntl(new Date(item?.created_at), new Date())
        : ''
      return <Information key={item.id} label={item.value} text={date} />
    })
  }, [selectedChannel])

  function descendingOrderSort(ticketA: ITicketModel, ticketB: ITicketModel) {
    var timestampA = parseInt(ticketA.CreationDateTime.match(/\d+/)[0])
    var timestampB = parseInt(ticketB.CreationDateTime.match(/\d+/)[0])
    return timestampB - timestampA
  }

  useEffect(() => {
    if (ticketsData) setTicketsDataWrittable([...ticketsData])
  }, [ticketsData])

  return (
    <S.Container>
      <S.CustomerAvatarContainer>
        <CustomerAvatar />
      </S.CustomerAvatarContainer>
      <Tabs
        initialValue="PROFILE"
        tabItems={[
          {
            icon: 'ph-user',
            id: 'PROFILE',
            onClick: () => setTabActive('PROFILE'),
            text: 'Perfil',
          },
          {
            badge: ticketsData?.length ? ticketsData?.length : undefined,
            badgeColor: 'primary',
            icon: 'ph-ticket',
            id: 'TICKETS',
            onClick: () => setTabActive('TICKETS'),
            text: 'Tickets',
          },
        ]}
      />
      {tabActive === 'PROFILE' && (
        <>
          <S.CustomerInformationContainer>
            <Form resetOnSubmit onSubmit={handleCustomerEdit}>
              <S.CustomerInformationColumn>
                <Information label="ID" text={customer?.externalId ?? '-'} />
                <Information
                  label="Nome"
                  text={customer?.customerName ?? '-'}
                  edit={edit}
                  onChange={handleChangeCustomer('customerName')}
                  type="text"
                />
                <Information
                  label="E-mail"
                  text={
                    customer?.customerMail && customer?.customerMail !== ' '
                      ? customer.customerMail
                      : '-'
                  }
                  edit={edit}
                  type="email"
                  onChange={handleChangeCustomer('customerMail')}
                />
                <Information
                  label="Whatsapp"
                  text={format(customer?.contact) ?? '-'}
                  edit={edit}
                  type="tel"
                  onChange={handleChangeCustomer('contact')}
                />
                <Information
                  label="Telefone"
                  text={format(customer?.contact) ?? '-'}
                  type="tel"
                />
              </S.CustomerInformationColumn>
              {edit && (
                <S.CustomerInformationRow>
                  <Button
                    label="Salvar"
                    variant="underlinedText"
                    color="success"
                    type="submit"
                  />
                  <Button
                    label="Cancelar"
                    variant="underlinedText"
                    color="alert"
                    onClick={() => {
                      handleCustomer(selectedChannel?.customer)
                      toggleEdit()
                    }}
                  />
                </S.CustomerInformationRow>
              )}
            </Form>
            {!edit && (
              <S.CustomerInformationColumn>
                <Button
                  label="editar"
                  variant="underlinedText"
                  color="primary"
                  onClick={toggleEdit}
                  disabled={selectedChannel ? false : true}
                />
              </S.CustomerInformationColumn>
            )}
          </S.CustomerInformationContainer>
          <S.ActivityHeaderContainer>
            <Text content="Atividades" weight="black" size="large" />
            <div>
              <Button
                color="primary"
                label="ver mais"
                onClick={function noRefCheck() {}}
                variant="underlinedText"
                disabled={selectedChannel ? false : true}
              />
            </div>
          </S.ActivityHeaderContainer>
          <S.ActivityListContainer>
            {renderActivities()}
          </S.ActivityListContainer>
        </>
      )}
      {tabActive === 'TICKETS' && (
        <>
          {status !== 'loading' ? (
            <>
              <S.ActivityHeaderContainer>
                <div>
                  <Button
                    color="primary"
                    label="abrir ticket"
                    onClick={toggleModal}
                    variant="underlinedText"
                    disabled={selectedChannel ? false : true}
                  />
                </div>
              </S.ActivityHeaderContainer>
              <S.TicketListContainer>
                {ticketsData &&
                  ticketsData.length > 0 &&
                  ticketsDataWrittable &&
                  ticketsDataWrittable.length > 0 &&
                  ticketsDataWrittable
                    .sort((ticketA, ticketB) =>
                      descendingOrderSort(ticketA, ticketB)
                    )
                    .map((ticket) => (
                      <TicketCard key={ticket.ID} data={ticket} />
                    ))}
              </S.TicketListContainer>
            </>
          ) : (
            <S.CustomerLoadingContainer>
              <Loading size={50} />
            </S.CustomerLoadingContainer>
          )}
        </>
      )}
      <Modal open={showModal}>
        <Form onSubmit={handleSubmitTicket}>
          {() => (
            <S.FormContainer>
              <Dropdown
                label="Ocorrência"
                placeholder="Selecione a categoria da ocorrência"
                options={occurrenceCategories}
                name="ocorrencia"
                onChange={handleChangeTicket('IncidentServiceIssueCategoryID')}
                required
              />
              <Dropdown
                label="Categoria da causa"
                placeholder="Selecione a categoria da causa"
                options={renderCategoy}
                name="causa"
                onChange={handleChangeTicket('CauseServiceIssueCategoryID')}
                required
              />
              <Textarea
                label="Assunto"
                name="assunto"
                onChange={handleChangeTicket('Name')}
                rows={5}
                required
              />
              <Input
                label="Contato"
                name="contact"
                type="tel"
                placeholder="(11) 98902 0536"
                initialValue={format(customer?.contact)}
              />
              <Dropdown
                name="priority"
                label="Prioridade"
                placeholder="Selecione a prioridade do ticket"
                options={priorities}
                onChange={handleChangeTicket('ServicePriorityCode')}
              />
              <S.FormButtonsContainer>
                <Button
                  label="Cancelar"
                  variant="default"
                  color="alert"
                  onClick={() => {
                    toggleModal()
                  }}
                />
                <Button
                  label="Salvar"
                  variant="default"
                  color="success"
                  type="submit"
                />
              </S.FormButtonsContainer>
            </S.FormContainer>
          )}
        </Form>
      </Modal>
    </S.Container>
  )
}
export default CustomerDetails
