import { useCallback, useState } from 'react'
import * as crypto from 'crypto-js'
import { useAppDispatch, useAppSelector } from 'store'
import { appAuth } from './services'
import { resetTickets } from 'models/tickets/model'
import { resetUnreadMessages } from 'models/unreadMessages/model'
import { resetMessages } from 'models/messages/model'
import { resetChannels } from 'models/channel/model'
import { batch } from 'react-redux'

export const useApp = () => {
  const dispatch = useAppDispatch()
  const { data, status, error } = useAppSelector((state) => state.app)
  const [values] = useState({
    passHash: process.env.REACT_APP_PASS_HASH,
    password: process.env.REACT_APP_ORBIT_PASSWORD,
    username: process.env.REACT_APP_ORBIT_USER,
  })

  const handleReset = useCallback(() => {
    batch(() => {
      dispatch(resetChannels())
      dispatch(resetMessages())
      dispatch(resetUnreadMessages())
      dispatch(resetTickets())
    })
  }, [dispatch])

  const bootstrapApp = useCallback(async () => {
    const cPassword = crypto.AES.encrypt(
      values.password,
      values.passHash
    ).toString()

    await dispatch(appAuth({ cPassword, username: values.username }))
  }, [dispatch, values])

  return {
    data,
    status,
    bootstrapApp,
    handleReset,
    error,
  }
}
