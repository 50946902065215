import { ListSpacing, SectionPadding } from 'pages/Main/shared/styleUtils'
import styled from 'styles/styled'

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${SectionPadding}
`

export const ChannelCardListContainer = styled.div`
  overflow-y: auto;
  gap: ${({ theme }) => `${theme.spacing[4]} 0`};
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[4]};
  ${ListSpacing}
`
