import { useEffect } from 'react'
import * as S from './Main.styles'
import ChannelListHeader from './parts/ChannelListHeader'
import ChannelsList from './parts/ChannelsList'
import Chat from './parts/Chat'
import ChatHeader from './parts/ChatHeader'
import CustomerDetails from './parts/CustomerDetails'
import UserStatus from './parts/UserStatus'
import { useApp } from 'models/app/hooks'
import { SocketConnection } from './parts/Socket'

const Main = () => {
  const { handleReset } = useApp()

  useEffect(() => {
    handleReset()
  }, [handleReset])

  return (
    <S.Container>
      <S.LeftSection>
        <ChannelListHeader />
        <UserStatus />
        <ChannelsList />
      </S.LeftSection>
      <S.MiddleSection>
        <ChatHeader />
        <Chat />
      </S.MiddleSection>
      <S.RightSection>
        <CustomerDetails />
      </S.RightSection>
      <SocketConnection />
    </S.Container>
  )
}

export default Main
