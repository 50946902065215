export const causeCategories = [
  {
    text: 'Produtos Descontinuados',
    value: 'CA_25',
  },
  {
    text: 'Falta do produto no estoque',
    value: 'CA_24',
  },
  {
    text: 'Farmácias/Drogarias',
    value: 'CA_26',
  },
  {
    text: 'Atendimento',
    value: 'CA_21',
  },
  {
    text: 'Política de Troca',
    value: 'CA_22',
  },
  {
    text: 'Produtos/Medicamentos',
    value: 'CA_20',
  },
  {
    text: 'Estabilidade',
    value: 'CA_18',
  },
  {
    text: 'Armazenamento/Conservação',
    value: 'CA_17',
  },
  {
    text: 'Formas Farmacêuticas',
    value: 'CA_16',
  },
  {
    text: 'Caracteristicas Organolépticas e físicas',
    value: 'CA_15',
  },
  {
    text: 'Farmacocinética',
    value: 'CA_14',
  },
  {
    text: 'Vias de administração',
    value: 'CA_13',
  },
  {
    text: 'Interações',
    value: 'CA_12',
  },
  {
    text: 'Composição',
    value: 'CA_11',
  },
  {
    text: 'Posologia',
    value: 'CA_10',
  },
  {
    text: 'Indicação',
    value: 'CA_5',
  },
  {
    text: 'Distribuidora',
    value: 'CA_47',
  },
  {
    text: 'Pessoa Jurídica',
    value: 'CA_46',
  },
  {
    text: 'Pessoa Física',
    value: 'CA_45',
  },
  {
    text: 'Reembolso',
    value: 'CA_31',
  },
  {
    text: 'Engano',
    value: 'CA_43',
  },
  {
    text: 'Retorno',
    value: 'CA_42',
  },
  {
    text: 'Laudo Técnico',
    value: 'CA_41',
  },
  {
    text: 'Cadastro de Currículos',
    value: 'CA_40',
  },
  {
    text: 'Amostra Grátis',
    value: 'CA_39',
  },
  {
    text: 'Código de postagem',
    value: 'CA_37',
  },
  {
    text: 'Posição de entrega',
    value: 'CA_36',
  },
  {
    text: 'Descontos',
    value: 'CA_35',
  },
  {
    text: 'Elogios',
    value: 'CA_34',
  },
  {
    text: 'Sugestões',
    value: 'CA_33',
  },
  {
    text: 'Visitação Médica',
    value: 'CA_32',
  },
  {
    text: 'Interação FVG X Consumidor',
    value: 'CA_29',
  },
  {
    text: 'Triagem',
    value: 'CA_28',
  },
]

export const salesPointCategory = ['CA_25', 'CA_24', 'CA26']
export const protestCategory = ['CA_22', 'CA_21', 'CA_20']
export const pharmaceuticalAttentionCategory = [
  'CA_18',
  'CA_17',
  'CA_16',
  'CA_15',
  'CA_14',
  'CA_13',
  'CA_12',
  'CA_11',
  'CA_10',
  'CA_5',
]
export const exchangesCategory = ['CA_47', 'CA_46', 'CA_45', 'CA_31']
export const generalInformationCategory = [
  'CA_43',
  'CA_42',
  'CA_41',
  'CA_40',
  'CA_39',
]
export const solicitationsCategory = [
  'CA_37',
  'CA_36',
  'CA_35',
  'CA_34',
  'CA_33',
  'CA_32',
]
export const pharmacovigilanceCategory = ['CA_29', 'CA_28']

export const occurrenceCategories = [
  {
    text: 'Pontos de Vendas',
    value: 'CA_23',
  },
  {
    text: 'Reclamações',
    value: 'CA_19',
  },
  {
    text: 'Atenção Farmacêutica',
    value: 'CA_4',
  },
  {
    text: 'Trocas',
    value: 'CA_44',
  },
  {
    text: 'Informações Gerais',
    value: 'CA_38',
  },
  {
    text: 'Solicitações',
    value: 'CA_30',
  },
  {
    text: 'Farmacovigilância',
    value: 'CA_27',
  },
]

export const priorities = [
  {
    text: 'Imediata',
    value: '1',
  },
  {
    text: 'Urgente',
    value: '2',
  },
  {
    text: 'Normal',
    value: '3',
  },
  {
    text: 'Baixa',
    value: '7',
  },
]
