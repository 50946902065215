import styled from 'styles/styled'

export const Container = styled.button<{
  isChecked?: boolean
}>`
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
  border-radius: ${({ theme }) => theme.border.radius.regular};
  background-color: ${({ isChecked, theme }) =>
    isChecked ? theme.color.greyScale[200] : 'transparent'};
  border-color: ${({ isChecked, theme }) =>
    isChecked ? 'transparent' : theme.color.greyScale[300]};
  border-style: solid;
  border-width: ${({ theme }) => theme.border.width.regular};
  cursor: pointer;
`
