import styled from 'styles/styled'

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing[3]};
`

export const WrapperButtonAssign = styled.div``
