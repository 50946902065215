import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'services/client'
import {
  IPostTicketModelResponse,
  ITicketModel,
  ITicketRequest,
  ITicketResponse,
} from './types'

export const postTicket = createAsyncThunk<
  IPostTicketModelResponse,
  ITicketModel
>('ticket/create', async (data, thunkAPI) => {
  const response = await api.post<IPostTicketModelResponse>(
    `cx-messages-service/api/tickets`,
    data
  )
  return response.data
})

export const fetchTickets = createAsyncThunk<ITicketResponse, ITicketRequest>(
  'ticket/fetchAll',
  async (_, thunkAPI) => {
    const response = await api.get<ITicketResponse>(
      `cx-messages-service/api/tickets/${_.customerId}`
    )
    return response.data
  }
)
