import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICustomerModel, ICustomerResponse } from './types'
import { updateCustomer } from './services'
import { loadingStatusType } from 'commons/types/load'

interface IInitialState {
  data: ICustomerModel | null
  status: loadingStatusType
}

const initialState: IInitialState = {
  data: null,
  status: 'idle',
}

export const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, { payload }: PayloadAction<ICustomerModel>) => {
      state.data = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        updateCustomer.fulfilled,
        (state, { payload }: PayloadAction<ICustomerResponse>) => {
          state.data = {
            ...state.data,
            ...payload.data,
          }
          state.status = 'success'
        }
      )
      .addCase(updateCustomer.rejected, (state, action) => {
        state.status = 'error'
      })
      .addCase(updateCustomer.pending, (state) => {
        state.status = 'loading'
      })
  },
})

// Action creators are generated for each case reducer function
export const { setCustomer } = customer.actions

export default customer.reducer
