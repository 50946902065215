import styled from 'styles/styled'

export const MessageListContainer = styled.ul`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[5]} 0;
  padding-top: ${({ theme }) => theme.spacing[3]};
  list-style-type: none;
`
