import styled from 'styles/styled'
import { Text } from 'Components/Typography'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.greyScale[100]};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
  border-radius: ${({ theme }) => theme.border.radius.regular};
  cursor: pointer;
  min-height: 60px;
  height: 60px;
`
export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const CustomerNameText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.texts.large};
  color: ${({ theme }) => theme.color.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
`
export const DateText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.texts.small};
  color: ${({ theme }) => theme.color.greyScale[800]};
`
export const MessageText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizes.texts.small};
  color: ${({ theme }) => theme.color.greyScale[600]};
`
