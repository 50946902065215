import styled from 'styles/styled'
import { GREYSCALE_SECTION_DIVIDER } from './shared/styleUtils'

export const Container = styled.div`
  display: flex;
  max-height: 100vh;
  background: ${({ theme }) => theme.color.greyScale[100]};
  flex-direction: row;
  height: 100vh;
`

export const LeftSection = styled.div`
  flex: 25%;
  flex-shrink: 3;
  flex-grow: 1;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background.paper};
`

export const MiddleSection = styled.div`
  flex: 50%;
  flex-shrink: 3;
  flex-grow: 3;
  flex-basis: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: ${({ theme }) =>
      theme.color.greyScale[GREYSCALE_SECTION_DIVIDER]}
    solid 1px;
`
export const RightSection = styled.div`
  flex: 25%;
  flex-shrink: 2;
  flex-grow: 1;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
