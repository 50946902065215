import styled from 'styles/styled'

export const Container = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius.regular};
  background-color: ${({ theme }) => theme.color.greyScale[50]};
  box-shadow: ${({ theme }) => theme.shadows[0]};
  padding: ${({ theme }) => theme.spacing[4]};
`

export const ChevronIconContainer = styled.div`
  cursor: pointer;
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
`
export const Body = styled.ul<{ isOpen: boolean }>`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0px')};
  transition: max-height 1s;
  transition-timing-function: ${({ isOpen }) =>
    isOpen ? 'linear' : 'cubic-bezier(0, 1, 0, 1)'};
`
