import scrollBar from './common/scrollBar'
import { createGlobalStyle } from './styled'

const GlobalStyle = createGlobalStyle`
*{
margin:0;
padding: 0;
box-sizing: border-box;
font-family:${({ theme }) => theme.typography.baseTypography.fontFamily};
}

*::before,
*::after {
  box-sizing: border-box;
}

${scrollBar}

`

export default GlobalStyle
