import { Text } from 'Components/Typography'
import { useChannel } from 'models/channel/hooks'
import * as S from './CustomerAvatar..styles'
import { format } from 'commons/functions/contact'
import { getInitials } from 'commons/functions/strings'

const CustomerAvatarVertical = () => {
  const { selectedChannel } = useChannel({ initialValue: 'ALL' })

  return (
    <S.Container>
      <S.AvatarContainer>
        <S.Avatar>
          {getInitials(selectedChannel?.customer?.customerName)}
        </S.Avatar>
      </S.AvatarContainer>
      <div>
        <S.CustomerNameText
          content={selectedChannel?.customer?.customerName ?? ' - '}
          weight="black"
          size="large"
        />
      </div>
      <div>
        <Text
          content={format(selectedChannel?.customer?.contact)}
          size="medium"
          weight="black"
        />
      </div>
    </S.Container>
  )
}

export default CustomerAvatarVertical
