import { ThemeProvider } from '@seidor/orbit-ds-core'
import { ReactNode } from 'react'
import GlobalStyle from 'styles/globalStyle'

interface IAppThemeProvider {
  children: ReactNode | ReactNode[]
}

export default function AppThemeProvider(props: IAppThemeProvider) {
  return (
    <ThemeProvider themeName="OrbitTheme">
      <GlobalStyle />
      {props.children}
    </ThemeProvider>
  )
}
